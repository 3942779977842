import { Environment } from './env-interface';
import { GreenCheckAwsEnvironmentName } from '@gcv/shared';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: Environment = {
  production: true,
  amplify: {
    aws_project_region: 'us-east-1',
    aws_cognito_identity_pool_id: 'us-east-1:de0b22a0-a7e5-4330-ba3e-12c053d8c1fb',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_mSvKEXjea',
    aws_user_pools_web_client_id: '76r5sdlf0gn0e1nh6tgn68dhke',
  },
  gcvConfig: {
    launchDarklyClientSideId: '5c12863affcc3e1712026bfe',
    webSocketUrl: 'wss://atsye5gagk.execute-api.us-east-1.amazonaws.com/Prod',
  },
  basePath: 'https://stagingapi.greencheckverified.com',
  storageConfig: {
    salesFile: 'staging-new-data-file-bucket',
    document: 'staging-gcv-company-document-store',
    regulatoryGuide: '',
    coreTransactions: 'staging-core-transaction-data',
    userDocument: 'staging-user-documents-file-bucket',
  },
  env: GreenCheckAwsEnvironmentName.STAGING,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
